
import React from 'react';
import 'antd/dist/antd.css';
import { Col, Layout, Menu, Row } from 'antd';
import { Link } from 'gatsby';
import cover3 from '../../images/cover.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Typography } from 'antd';
import { Button } from 'antd';

const { Title } = Typography;

const AboutUsCover = (props: any) => (
  <Row className="vh-100" style={{backgroundBlendMode: "overlay", background: `url(${cover3})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "#00000050"}}>
      <Col className="order-2 order-md-1 d-flex flex-column justify-content-center px-5" style={{textAlign: "left"}} >
        <Title style={{fontSize: "3em"}} className="text-light">Agile Model</Title>
        <span className="text-light border-top" style={{paddingRight: "12em"}}> </span><br />
        {/* <Title level={4} className="text-light">We are experts in delivering efficient solutions in Salesforce world</Title> */}
        <Title level={4} className="text-light">Next level management and solution delivery</Title>
      </Col>
  </Row>
);

export default AboutUsCover;