
import React from 'react';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import cert1 from '../../images/cert-1.png';
import cert2 from '../../images/cert-2.png';
import cert3 from '../../images/cert-3.png';
import cert4 from '../../images/cert-4.png';
import cert5 from '../../images/cert-5.png';
import cert6 from '../../images/cert-6.png';
import cert7 from '../../images/cert-7.png';
import cert8 from '../../images/cert-8.png';
import cert9 from '../../images/cert-9.png';
import { Col, Row } from 'antd';

const CertificatesContainerOld = (props: any) => (
  <div className="pb-4 text-center bg-dark" style={{width: "99vw"}}>
      <h1 className="text-light pb-2">Our Expertise</h1>
      <p className="text-light pb-3">Who else can deliver customer delight other than certified experts.</p>
      <div className="row d-flex justify-content-center">
          <div className="col-auto">
              <img src={cert1} />
          </div>
          <div className="col-auto">
              <img src={cert2} />
          </div>
          <div className="col-auto">
              <img src={cert3} />
          </div>
          <div className="col-auto">
              <img src={cert4} />
          </div>
          <div className="col-auto">
              <img src={cert5} />
          </div>
      </div>
  </div>
);

const CertificatesContainer = (props: any) => (
  <div className="text-center bg-dark p-3 pb-5">
      <h1 className="text-light">Our Expertise</h1>
      <p className="text-light pb-3">Who else can deliver customer delight other than certified experts.</p>
      <Row justify='center' gutter={[48, 24]} className="mx-md-5 px-md-5">
          <Col>
            <img width="180" src={cert1} />
          </Col>
          <Col>
            <img width="180" src={cert2} />
          </Col>
          <Col>
            <img width="180" src={cert3} />
          </Col>
          <Col>
            <img width="180" src={cert4} />
          </Col>
          <Col>
            <img width="180" src={cert5} />
          </Col>
          <Col>
            <img width="180" src={cert6} />
          </Col>
          <Col>
            <img width="180" src={cert7} />
          </Col>
          <Col>
            <img width="180" src={cert8} />
          </Col>
      </Row>
  </div>
);

export default CertificatesContainer;