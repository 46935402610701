import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import PageLayout from '../components/Layout';
import { Container } from 'react-bootstrap';
import CertificatesContainer from '../components/CertificatesContainer';
import AboutUsCover from '../components/AboutUsCover';
import ServicesContainer from '../components/ServicesContainer';
import { Helmet } from "react-helmet";
import Meta from '../components/Meta';

const { Content } = Layout;

export default (props) => {
  
  return (
  <PageLayout selectedKey="about-us">
    <Meta location={props.location} />
    <AboutUsCover />
    <CertificatesContainer />
    <ServicesContainer />
  </PageLayout>
)};