import React from 'react';
import 'antd/dist/antd.css';
import '../../styles/css/index.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import { Link } from 'gatsby';
import Header from '../../components/Header';
import Footer from '../Footer';

const { Content } = Layout;

export default (props: any) => {
  
  return (
  <Layout className="layout">
    <Header selectedKey={props.selectedKey}/>
    {props.children}
    <Footer />
  </Layout>
)};