import React from 'react';
import 'antd/dist/antd.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import PageLayout from '../../components/Layout';
import sfmcCover from '../../images/SFMC.jpg';
import pardotCover from '../../images/Pardot.png';
import mulesoftCover from '../../images/mulesoft.png';

const { Content } = Layout;

export default () => {

    return (
        <div>

            <div className="container container-fluid p-2 pt-5 text-center border-bottom">
                <h4>Our Salesforce consultants are experienced and hold Salesforce technology expertise in the following products implementation and customization.</h4>
            </div>

            <div className="m-5 mt-5">
                <div className="row my-3 border-bottom py-2">
                    <div className="order-sm-2 order-xs-2 order-md-1 order-2 col-md-7">
                        <h3 className="text-dark">Marketing Cloud</h3>
                        <p>With our Salesforce Marketing Cloud expertise, you can reach your customers at the right time in the right way. If you need to reach your individual customers in a more personalized way, Salesforce Marketing Cloud is the answer for you.</p>
                        <p>Our Marketing Cloud experts help you in understanding how you can reach your customers through email, text messages and social media along with the use of its major components, viz., Studios, Builders and Marketing Cloud Connect.</p>
                        <p>Marketing Cloud and Pardot allow you to focus on each individual customer, connecting every interaction you have with them, and marketing smarter with predictive analytics. Whether B2B or B2C, you can engage with, advertise to, and communicate with your customers & prospects like never before.</p>
                    </div>
                    <div className="order-sm-1 order-xs-1 order-md-2 order-1 col-md-5 d-flex justify-content-between align-items-center">
                        <img src={sfmcCover} className="img-fluid " alt="Marketing cloud" style={{ boxShadow: "0 2.5rem 1.88889rem -1rem rgb(0 0 0 / 15%)" }} />
                    </div>
                </div>
                <div className="row my-3 border-bottom py-2">
                    <div className="col-md-5 d-flex justify-content-between align-items-center">
                        <img src={pardotCover} className="img-fluid " alt="Pardot" style={{ boxShadow: "0 2.5rem 1.88889rem -1rem rgb(0 0 0 / 15%)" }} />
                    </div>
                    <div className="col-md-7 d-flex align-items-center">
                        <div>
                            <h3 className="text-dark">Pardot</h3>
                            <p>The success of any business ultimately boils down to its customers. A business needs in depth analysis of prospects to customers conversion. Pardot is a marketing automation platform provided by Salesforce to increase your marketing efforts, campaign effectiveness and much more.</p>
                            <p>With the help of our Pardot consultants, you can empower your Sales and Marketing team to achieve better ROI on their spent efforts and time.</p>
                        </div>
                    </div>
                </div>
                <div className="row my-3 border-bottom py-2">
                    <div className="order-sm-2 order-xs-2 order-md-1 order-2 col-md-7 d-flex justify-content-between align-items-center">
                        <div>
                            <h3 className="text-dark">Mulesoft</h3>
                            <p>MuleSoft — the world's #1 integration and API management platform — has helped thousands of customers automate, integrate, and execute faster than ever before. Just how fast? Find out <a href="https://www.mulesoft.com/lp/reports/integration-total-economic-impact">here</a>.</p>
                            <p>As a MuleSoft developers, we deliver best-in-className solutions that maximize ROI and accelerate their business. Whether on-premise or in the cloud, Wisecode combines our love for integration with your business' needs to develop a future-proof architecture built for success.</p>
                        </div>
                    </div>
                    <div className="order-sm-1 order-xs-1 order-md-2 order-1 col-md-5 d-flex justify-content-between align-items-center">
                        <img src={mulesoftCover} className="img-fluid " alt="Mulesoft" style={{ boxShadow: "0 2.5rem 1.88889rem -1rem rgb(0 0 0 / 15%)" }} />
                    </div>
                </div>
            </div>
        </div>
    )
};